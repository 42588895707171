@import url(https://fonts.googleapis.com/css2?family=Space+Mono&display=swap);

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}body{line-height:1}button{outline:0}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}q{display:inline;font-style:italic}q:before{content:'"';font-style:normal}q:after{content:'"';font-style:normal}textarea,input[type="text"],input[type="button"],input[type="submit"],input[type="reset"],input[type="search"],input[type="password"]{-webkit-appearance:none;appearance:none;border-radius:0}table{border-collapse:collapse;border-spacing:0}th,td{padding:2px}big{font-size:120%}small,sup,sub{font-size:80%}sup{vertical-align:super}sub{vertical-align:sub}dd{margin-left:20px}kbd,tt{font-family:courier;font-size:12px}ins{text-decoration:underline}del,strike,s{text-decoration:line-through}dt{font-weight:bold}address,cite,var{font-style:italic}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}*{box-sizing:border-box;-webkit-tap-highlight-color:transparent}
.sticky{}.bypostauthor{}.wp-caption{}.wp-caption-text{}.gallery-caption{}.alignright{}.alignleft{}.aligncenter{}.screen-reader-text{clip:rect(1px, 1px, 1px, 1px);position:absolute !important}

@font-face {
  font-family: "mabry";
  src: url(/static/media/mabry.abda00d6.eot);
  src: url(/static/media/mabry.abda00d6.eot?#iefix) format("embedded-opentype"),
       url(/static/media/mabry.d13ca447.svg#mabry) format("svg"),
       url(/static/media/mabry.e79ab1ae.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

*{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: -webkit-fill-available;
}

body {
  font-family: "mabry";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-weight: 400;
  overflow-x: hidden;
  background: #eb1909;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.p20  {padding: 20px;}
.pr20 {padding-right: 20px;}
.pb20 {padding-bottom: 20px;}
.p40  {padding: 40px;}
.pr40 {padding-right: 40px;}
.pv40 {padding-top: 20px; padding-bottom: 20px;}
.pb40 {padding-bottom: 40px;}
.pt40  {padding-top: 40px;}

.p80  {padding: 40px;}
.pt80 {padding-top: 40px;}
.pb80 {padding-bottom: 40px;}
.pr80 {padding-right: 40px;}
.pl80 {padding-left: 40px;}

.pv120  {padding-top: 120px; padding-bottom: 120px;}
.pt120  {padding-top: 120px;}
.pb120  {padding-bottom: 120px;}
.pl120  {padding-left: 120px;}
.pr120  {padding-right: 120px;}
.ph120  {padding-left: 120px; padding-right: 120px;}

.pv160  {padding-top: 80px; padding-bottom: 80px;}
.pt160  {padding-top: 80px;}
.pb160  {padding-bottom: 80px;}
.pl160  {padding-left: 80px;}
.pr160  {padding-right: 80px;}
.ph160  {padding-left: 80px; padding-right: 80px;}

a {
  text-decoration: none;
}

.hr_yellow {
  border-top: none;
  border-bottom: 1px solid #ffc800;
}


.App {
  height: 100vh;
  background-color: #eb1909;
  text-align: left;
}

.container {
  width: 60%;
  padding: 0 10% 0 10%;
}

.header-container {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  align-content:space-around;
  grid-gap: 20px;
  gap: 20px;
}

.header {
  margin: 0;
  font-family: "mabry", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 7em;
}

.gallery {
  margin: 0;
  font-family: "mabry", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 4em;
}

.solar_teaser {
  width:  100%;
  height: 100%;
  max-width: 400px;
}

.solar_preview {
    margin: 0 20px 20px 0;

}

video::-webkit-media-controls-start-playback-button {
    display: none;
}

video.loading {
  background: black url("https://martinhoura.net/media/loading.png") center center no-repeat;
}

.gradient-header {
  background: -webkit-linear-gradient(322deg, #d50c04 0%, #ffc800 50%, #fff 75%);
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  -webkit-animation: gradient-animation 12s ease 0s infinite;
          animation: gradient-animation 12s ease 0s infinite;
}

.gradient-text {
  background: -webkit-linear-gradient(322deg, #d50c04 0%, #ffc800 40%, #fff 75%);
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  -webkit-animation: gradient-animation 13s ease 0s infinite;
          animation: gradient-animation 13s ease 0s infinite;
  color:  #fff;
  font-size: 26px;
  line-height: 28px;
  margin-bottom: 24px;
}

.gradient-text a a:link a:visited {
    border-bottom: 0px;
    text-decoration:none;
}

.gradient-text a:hover {
    border-bottom: 1px solid; 
    color: #fff;
}

.mint-container {
  margin-top: 20px;
  color: #eda282;
}

.cta-button {
  height: 45px;
  border: 0;
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 0px;
  color: #fff;
  font-family: "mabry", Helvetica, sans-serif;
  font-size: 16px;
  color: #000;
}

.connect-wallet-button {
  background: #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.connect-wallet-button:hover {
  background: #ffff97;
}

.mint-button {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.mint-button:hover {
  background: #ffff97;
}

.no-mint-button {
  background: #000;
  color: #fff;
  margin-right: 15px;
}

.opensea-button {
  background-color: rgb(32, 129, 226);
}

.mint-count {
  color: #eda282;
  font-family: 'Space Mono', monospace;
  font-size: 1em;
  padding-top: 5px;
  padding-bottom: 30px;
}

.footer {
  color: #eda282;
  font-family: 'Space Mono', monospace;
  font-size: 1em;
  padding-top: 5px;
  padding-bottom: 30px;
}

.footerLink {
    color: #eda282;
    border-bottom: 0px;
    text-decoration:none;
}

.footerLink a a:link a:visited {
    color: #eda282;
    border-bottom: 0px;
    text-decoration:none;
}

.footerLink a:hover {
    border-bottom: 10px bottom #eda282;
    color: #fff;
}


.wallet-connect {
  color: #eda282;
  font-family: 'Space Mono', monospace;
  font-size: 1em;
  padding-top: 5px;
  padding-bottom: 50px;
}

@media screen and (max-width: 1200px) {
  .header {
    margin: 0;
    font-family: "mabry", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 5em;
  }

  .container {
    width: 100%;
    padding: 0 5% 0 5%;
  }

  p {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 18px;
}
}

/* KeyFrames */
@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

