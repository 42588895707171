a {
  text-decoration: none;
}

.hr_yellow {
  border-top: none;
  border-bottom: 1px solid #ffc800;
}


.App {
  height: 100vh;
  background-color: #eb1909;
  text-align: left;
}

.container {
  width: 60%;
  padding: 0 10% 0 10%;
}

.header-container {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  align-content:space-around;
  gap: 20px;
}

.header {
  margin: 0;
  font-family: "mabry", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 7em;
}

.gallery {
  margin: 0;
  font-family: "mabry", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 4em;
}

.solar_teaser {
  width:  100%;
  height: 100%;
  max-width: 400px;
}

.solar_preview {
    margin: 0 20px 20px 0;

}

video::-webkit-media-controls-start-playback-button {
    display: none;
}

video.loading {
  background: black url("https://martinhoura.net/media/loading.png") center center no-repeat;
}

.gradient-header {
  background: -webkit-linear-gradient(322deg, #d50c04 0%, #ffc800 50%, #fff 75%);
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  animation: gradient-animation 12s ease 0s infinite;
}

.gradient-text {
  background: -webkit-linear-gradient(322deg, #d50c04 0%, #ffc800 40%, #fff 75%);
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  animation: gradient-animation 13s ease 0s infinite;
  color:  #fff;
  font-size: 26px;
  line-height: 28px;
  margin-bottom: 24px;
}

.gradient-text a a:link a:visited {
    border-bottom: 0px;
    text-decoration:none;
}

.gradient-text a:hover {
    border-bottom: 1px solid; 
    color: #fff;
}

.mint-container {
  margin-top: 20px;
  color: #eda282;
}

.cta-button {
  height: 45px;
  border: 0;
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 0px;
  color: #fff;
  font-family: "mabry", Helvetica, sans-serif;
  font-size: 16px;
  color: #000;
}

.connect-wallet-button {
  background: #fff;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.connect-wallet-button:hover {
  background: #ffff97;
}

.mint-button {
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.mint-button:hover {
  background: #ffff97;
}

.no-mint-button {
  background: #000;
  color: #fff;
  margin-right: 15px;
}

.opensea-button {
  background-color: rgb(32, 129, 226);
}

.mint-count {
  color: #eda282;
  font-family: 'Space Mono', monospace;
  font-size: 1em;
  padding-top: 5px;
  padding-bottom: 30px;
}

.footer {
  color: #eda282;
  font-family: 'Space Mono', monospace;
  font-size: 1em;
  padding-top: 5px;
  padding-bottom: 30px;
}

.footerLink {
    color: #eda282;
    border-bottom: 0px;
    text-decoration:none;
}

.footerLink a a:link a:visited {
    color: #eda282;
    border-bottom: 0px;
    text-decoration:none;
}

.footerLink a:hover {
    border-bottom: 10px bottom #eda282;
    color: #fff;
}


.wallet-connect {
  color: #eda282;
  font-family: 'Space Mono', monospace;
  font-size: 1em;
  padding-top: 5px;
  padding-bottom: 50px;
}

@media screen and (max-width: 1200px) {
  .header {
    margin: 0;
    font-family: "mabry", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 5em;
  }

  .container {
    width: 100%;
    padding: 0 5% 0 5%;
  }

  p {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 18px;
}
}

/* KeyFrames */
@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
