
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}body{line-height:1}button{outline:0}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}q{display:inline;font-style:italic}q:before{content:'"';font-style:normal}q:after{content:'"';font-style:normal}textarea,input[type="text"],input[type="button"],input[type="submit"],input[type="reset"],input[type="search"],input[type="password"]{-webkit-appearance:none;appearance:none;border-radius:0}table{border-collapse:collapse;border-spacing:0}th,td{padding:2px}big{font-size:120%}small,sup,sub{font-size:80%}sup{vertical-align:super}sub{vertical-align:sub}dd{margin-left:20px}kbd,tt{font-family:courier;font-size:12px}ins{text-decoration:underline}del,strike,s{text-decoration:line-through}dt{font-weight:bold}address,cite,var{font-style:italic}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}*{box-sizing:border-box;-webkit-tap-highlight-color:transparent}
.sticky{}.bypostauthor{}.wp-caption{}.wp-caption-text{}.gallery-caption{}.alignright{}.alignleft{}.aligncenter{}.screen-reader-text{clip:rect(1px, 1px, 1px, 1px);position:absolute !important}

@font-face {
  font-family: "mabry";
  src: url("mabry.eot");
  src: url("mabry.eot?#iefix") format("embedded-opentype"),
       url("mabry.svg#mabry") format("svg"),
       url("mabry.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');

*{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: -webkit-fill-available;
}

body {
  font-family: "mabry";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-weight: 400;
  overflow-x: hidden;
  background: #eb1909;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.p20  {padding: 20px;}
.pr20 {padding-right: 20px;}
.pb20 {padding-bottom: 20px;}
.p40  {padding: 40px;}
.pr40 {padding-right: 40px;}
.pv40 {padding-top: 20px; padding-bottom: 20px;}
.pb40 {padding-bottom: 40px;}
.pt40  {padding-top: 40px;}

.p80  {padding: 40px;}
.pt80 {padding-top: 40px;}
.pb80 {padding-bottom: 40px;}
.pr80 {padding-right: 40px;}
.pl80 {padding-left: 40px;}

.pv120  {padding-top: 120px; padding-bottom: 120px;}
.pt120  {padding-top: 120px;}
.pb120  {padding-bottom: 120px;}
.pl120  {padding-left: 120px;}
.pr120  {padding-right: 120px;}
.ph120  {padding-left: 120px; padding-right: 120px;}

.pv160  {padding-top: 80px; padding-bottom: 80px;}
.pt160  {padding-top: 80px;}
.pb160  {padding-bottom: 80px;}
.pl160  {padding-left: 80px;}
.pr160  {padding-right: 80px;}
.ph160  {padding-left: 80px; padding-right: 80px;}
